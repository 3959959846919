import React, { useState, useEffect } from 'react';
import { Fab, Badge } from '@material-ui/core';
import clsx from 'clsx';
import ChatArea from '../ChatArea';
import useStyles from './styles';
import {useConfigContext} from '../../_context/config.context';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';

const launchButtonSize = 63;

export default function Launcher() {
  const { config } = useConfigContext();
  const {
    WIDE_MODE,
    CHAT_OPEN_BY_DEFAULT,
    GA,
    USE_CUSTOM_LAUNCHER_ICON,
    USE_CUSTOM_LAUNCHER,
    LAUNCHER_BUTTON_ID,
    MINIMIZE_LAUNCHER,
  } = config;
  const [chatIsOpen, setChatIsOpen] = useState(CHAT_OPEN_BY_DEFAULT || WIDE_MODE);
  const [currentIcon, setCurrentIcon] = useState('far fa-comments');
  const [notificationCounter, setNotificationCounter] = useState(0);
  const [isBouncing, setIsBouncing] = useState(false); // State to control bouncing
  const classes = useStyles({ chatIsOpen, launchButtonSize, ...config });
  const [iconIsMinimised, setIconIsMinimised] = useState(false);

  const toggleOpenState = () => {
    if (!chatIsOpen) setNotificationCounter(0);
    setChatIsOpen(!chatIsOpen);
    !chatIsOpen ? setCurrentIcon('fas fa-times') : setCurrentIcon('far fa-comments');
  };

  const toggleIconState = () => {
    setIconIsMinimised(!iconIsMinimised);
  };

  const updateNotificationCount = () => {
    if (!chatIsOpen) setNotificationCounter(notificationCounter + 1);
  };

  useEffect(() => {
    // Load Google Analytics once the session begins
    const gtag = GA.length > 0 && window.gtag ? window.gtag : null;
    if (gtag) {
      GA.forEach(({ type, name, payload }) => {
        gtag(type, name, payload);
      });
    }

    // Bounce the icon after 30 seconds
    const bounceTimer = setTimeout(() => {
      setIsBouncing(true); // Start bouncing
    }, 5000); // 5 seconds in milliseconds

    return () => {
      clearTimeout(bounceTimer);
    };
  }, [GA]);

  useEffect(() => {
    if (isBouncing) {
      const bounceInterval = setInterval(() => {
        setIsBouncing((prevIsBouncing) => !prevIsBouncing); // Toggle bouncing
      }, 500); // Adjust the duration and timing as needed

      return () => {
        clearInterval(bounceInterval);
        setIsBouncing(false); // Stop bouncing when the component unmounts
      };
    }
  }, [isBouncing]);

  return (
    <>
      <ChatArea
        triggerNotification={updateNotificationCount}
        launchButtonSize={launchButtonSize}
        chatIsOpen={chatIsOpen}
        toggleOpenState={toggleOpenState}
      />

      {/* Apply the bounce class conditionally */}
      {MINIMIZE_LAUNCHER && !chatIsOpen && (
        <Fab
          data-testid="toggle-icon-minimize"
          aria-label="minimize-icon"
          disableRipple
          className={clsx(
            classes.iconMinimisationButton,
            iconIsMinimised && 'icon-minimised'
          )}
          onClick={toggleIconState}
        >
          {!iconIsMinimised && (
            <CloseRoundedIcon
              className={clsx(classes.closeButton, iconIsMinimised && 'icon-minimised')}
              onClick={toggleIconState}
            />
          )}
        </Fab>
      )}

      <Fab
        data-testid="toggle-chat-window"
        aria-label="open-chat"
        disableRipple
        id={LAUNCHER_BUTTON_ID}
        className={clsx(
          classes.launcherButton,
          chatIsOpen && 'chat-open',
          isBouncing && classes.bounce // Conditionally apply the bounce class
        )}
        onClick={toggleOpenState}
      >
        <Badge
          badgeContent={notificationCounter > 0 ? notificationCounter : null}
          overlap="circle"
          classes={{
            badge: classes.badge,
          }}
        >
          {!USE_CUSTOM_LAUNCHER_ICON && !USE_CUSTOM_LAUNCHER && (
            <i
              data-testid="launch-icon"
              className={clsx(
                classes.messageIcon,
                currentIcon,
                chatIsOpen && 'open'
              )}
            />
          )}
        </Badge>
      </Fab>
    </>
  );
}
